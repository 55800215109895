import React from 'react';
import ContentLoader from 'react-content-loader';
import { withViewport } from '../../util/uiHelpers';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const SkeletonLoader = props => {
  const { viewport, noOfSlides, isInboxPage, isDashboardPage, ...rest } = props;

  const isMobile = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  let slideWidth = viewport && noOfSlides ? viewport.width / noOfSlides - 18 : 276;
  const slideViewBoxWidth = viewport && noOfSlides && viewport.width / noOfSlides;
  const LayoutMobile = typeof window !== 'undefined' && window.innerWidth < 576;

  if (slideWidth < 0) {
    slideWidth = 462
  };

  if (isMobile) {
    return <ContentLoader
      speed={2}
      width={210}
      height={230}
      viewBox="0 0 210 230"
      backgroundColor="#dddddd"
      foregroundColor="#ecebeb"
      {...rest}
    >
      <rect x="29" y="85" rx="0" ry="0" width="103" height="8" />
      <rect x="3" y="5" rx="8" ry="8" width="204" height="138" />
      <rect x="6" y="152" rx="0" ry="0" width="138" height="11" />
      <rect x="6" y="171" rx="0" ry="0" width="153" height="10" />
      <rect x="6" y="191" rx="0" ry="0" width="130" height="11" />
      <rect x="6" y="212" rx="0" ry="0" width="92" height="11" />
    </ContentLoader>
  } else if (isInboxPage) {
    // console.log('layoutmobile', LayoutMobile);
    if (LayoutMobile) {
      return <ContentLoader
        height={90}
        width={320}
        viewBox="0 0 320 54"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...rest}
      >
        {/* <circle cx="25" cy="25" r="22" /> */}
        <rect x="60" y="8" rx="5" ry="0" width="60%" height="15" />
        <rect x="60" y="8" rx="5" ry="0" width="60%" height="15" />

        <rect x="60" y="29" rx="3" ry="0" width="90%" height="15" />

        <rect x="0" y="100" rx="0" ry="0" width="100%" height="1" />
      </ContentLoader>
    } else {
      return <ContentLoader
        height={90}
        width={720}
        viewBox="0 0 720 54"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...rest}
      >
        {/* <circle cx="25" cy="25" r="22" /> */}
        <rect x="60" y="8" rx="5" ry="0" width="30%" height="15" />
        <rect x="650" y="8" rx="5" ry="0" width="10%" height="15" />
        <rect x="60" y="29" rx="3" ry="0" width="40%" height="15" />
        <rect x="620" y="29" rx="3" ry="0" width="15%" height="15" />

        <rect x="0" y="100" rx="0" ry="0" width="100%" height="1" />
      </ContentLoader>
    }
  } else if (isDashboardPage) {
    // console.log('layoutmobile', LayoutMobile);
    if (LayoutMobile) {
      return <ContentLoader
        height={90}
        width={320}
        viewBox="0 0 320 54"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...rest}
      >
        {/* <circle cx="25" cy="25" r="22" /> */}
        <rect x="60" y="8" rx="5" ry="0" width="60%" height="15" />
        <rect x="60" y="8" rx="5" ry="0" width="60%" height="15" />
        <rect x="60" y="8" rx="5" ry="0" width="60%" height="15" />

        <rect x="60" y="29" rx="3" ry="0" width="90%" height="15" />
        <rect x="60" y="29" rx="3" ry="0" width="90%" height="15" />

        <rect x="0" y="100" rx="0" ry="0" width="100%" height="1" />
      </ContentLoader>
    } else {
      return <ContentLoader
        width={viewport.width}
        height={400}
        viewBox={"0 0 " + viewport.width + " 400"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...rest}
      >
        <rect x="67" y="140" rx="10" ry="10" width="85" height="19" />
        <rect x="188" y="141" rx="10" ry="10" width="169" height="19" />
        <rect x="402" y="140" rx="10" ry="10" width="85" height="19" />
        <rect x="523" y="141" rx="10" ry="10" width="169" height="19" />
        <rect x="731" y="139" rx="10" ry="10" width="85" height="19" />
        <rect x="852" y="138" rx="10" ry="10" width="85" height="19" />

        <rect x="66" y="197" rx="10" ry="10" width="85" height="19" />
        <rect x="187" y="198" rx="10" ry="10" width="169" height="19" />
        <rect x="401" y="197" rx="10" ry="10" width="85" height="19" />
        <rect x="522" y="198" rx="10" ry="10" width="169" height="19" />
        <rect x="730" y="196" rx="10" ry="10" width="85" height="19" />
        <rect x="851" y="195" rx="10" ry="10" width="85" height="19" />

        <rect x="66" y="259" rx="10" ry="10" width="85" height="19" />
        <rect x="187" y="260" rx="10" ry="10" width="169" height="19" />
        <rect x="401" y="259" rx="10" ry="10" width="85" height="19" />
        <rect x="522" y="260" rx="10" ry="10" width="169" height="19" />
        <rect x="730" y="258" rx="10" ry="10" width="85" height="19" />
        <rect x="851" y="257" rx="10" ry="10" width="85" height="19" />

        <rect x="66" y="317" rx="10" ry="10" width="85" height="19" />
        <rect x="187" y="318" rx="10" ry="10" width="169" height="19" />
        <rect x="401" y="317" rx="10" ry="10" width="85" height="19" />
        <rect x="522" y="318" rx="10" ry="10" width="169" height="19" />
        <rect x="730" y="316" rx="10" ry="10" width="85" height="19" />
        <rect x="851" y="315" rx="10" ry="10" width="85" height="19" />

      </ContentLoader>
    }
  }

  return (
    <ContentLoader
      width={viewport.width}
      height={400}
      viewBox={"0 0 " + viewport.width + " 400"}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...rest}
    >
      <rect x="27" y="139" rx="4" ry="4" width="20" height="20" />
      <rect x="67" y="140" rx="10" ry="10" width="85" height="19" />
      <rect x="188" y="141" rx="10" ry="10" width="169" height="19" />
      <rect x="402" y="140" rx="10" ry="10" width="85" height="19" />
      <rect x="523" y="141" rx="10" ry="10" width="169" height="19" />
      <rect x="731" y="139" rx="10" ry="10" width="85" height="19" />
      <rect x="852" y="138" rx="10" ry="10" width="85" height="19" />
      <rect x="1424" y="137" rx="10" ry="10" width="68" height="19" />
      <rect x="26" y="196" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="197" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="198" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="197" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="198" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="196" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="195" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="203" r="12" />
      <rect x="26" y="258" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="259" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="260" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="259" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="260" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="258" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="257" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="265" r="12" />
      <rect x="26" y="316" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="317" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="318" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="317" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="318" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="316" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="315" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="323" r="12" />
      <rect x="26" y="379" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="380" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="381" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="380" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="381" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="379" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="378" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="386" r="12" />
      <rect x="978" y="138" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="195" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="257" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="315" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="378" rx="10" ry="10" width="169" height="19" />
      <rect x="1183" y="139" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="196" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="258" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="316" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="379" rx="10" ry="10" width="85" height="19" />
      <rect x="1305" y="137" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="194" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="256" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="314" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="377" rx="10" ry="10" width="85" height="19" />
      <circle cx="37" cy="97" r="11" />
      <rect x="26" y="23" rx="5" ry="5" width="153" height="30" />
      <circle cx="1316" cy="88" r="11" />
      <rect x="1337" y="94" rx="0" ry="0" width="134" height="3" />
      <circle cx="77" cy="96" r="11" />
    </ContentLoader>
  )
};

export default withViewport(SkeletonLoader);