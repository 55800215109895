import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconAddImageAndVideo.module.css';

const IconAddImageAndVideo = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
<svg xmlns="http://www.w3.org/2000/svg" width="59" height="53" viewBox="0 0 59 53" fill="none">
<path d="M7.375 9.9375H11.0625V13.25H7.375V9.9375ZM7.375 16.5625H11.0625V19.875H7.375V16.5625ZM33.1875 9.9375H36.875V13.25H33.1875V9.9375ZM54.2466 33.125H45.3159L49.7812 29.1396L54.2466 33.125ZM40.5625 24.8438C40.5625 24.3952 40.745 24.007 41.1099 23.6792C41.4748 23.3514 41.9069 23.1875 42.4062 23.1875C42.9056 23.1875 43.3377 23.3514 43.7026 23.6792C44.0675 24.007 44.25 24.3952 44.25 24.8438C44.25 25.2923 44.0675 25.6805 43.7026 26.0083C43.3377 26.3361 42.9056 26.5 42.4062 26.5C41.9069 26.5 41.4748 26.3361 41.1099 26.0083C40.745 25.6805 40.5625 25.2923 40.5625 24.8438ZM59 16.5625V39.75H55.3125V19.875H18.4375V34.0825L27.6562 25.8271L40.5625 37.395L44.25 34.0825V38.7925L43.1841 39.75H37.9409L27.6562 30.4854L18.4375 38.7925V43.0625H36.875V46.375H14.75V29.8125H0V0H44.25V16.5625H59ZM40.5625 16.5625V3.3125H36.875V6.625H33.1875V3.3125H11.0625V6.625H7.375V3.3125H3.6875V26.5H7.375V23.1875H11.0625V26.5H14.75V16.5625H40.5625ZM51.625 43.0625H59V46.375H51.625V53H47.9375V46.375H40.5625V43.0625H47.9375V36.4375H51.625V43.0625Z" fill="black"/>
</svg>
  );
};

IconAddImageAndVideo.defaultProps = { className: null };

IconAddImageAndVideo.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconAddImageAndVideo;
