import isArray from 'lodash/isArray';
import reduce from 'lodash/reduce';
import { sanitizeEntity } from './sanitize';
import { BUSINESS_LISTING_TYPE_PUBLISHED } from './types';
import { createSlug } from './urlHelpers';
import moment from 'moment';
import { boothTypes } from '../config/configListing';

const REACT_APP_MARKETPLACE_ROOT_URL = process.env.REACT_APP_MARKETPLACE_ROOT_URL;

export const mailTime = {
  fourHours: (4 * 60),
  fortyEightHours: (48 * 60),
}

export const templateIds = {
  RFP_NEW_MATCH_TEMPLATE_ID: "d-e703e957a4af4cc0957e397e3eb21bc6",
  RFP_BID_ACCEPTED_TEMPLATE_ID: "d-43150b29d5654d74bd2880c53c9ef0e8",
  RFP_BID_DECLINED_TEMPLATE_ID: "d-7fe17e42c9424ab28bec17096a61bd4d",
  RFP_BID_RECIEVED_TEMPLATE_ID: "d-ab03156fd7f543028015eb768f3a0b1e",
  RFP_TO_ADMIN_TEMPLATE_ID: "d-1cc2c550c65f4f78a705c2a8c176e58c",
  SIGNUP_DRAFT_TEMPLATE_ID: "d-8b30f930132f4034bbce40c256da8602",
  BUSINESS_DRAFT_TEMPLATE_ID: "d-361cef19b6e54a369af82e3a5514ca1f",
  EVENT_DRAFT_TEMPLATE_ID: "d-ff533a76d3ec43ccb39695d896770608",
  ENAGAGE_INCOMPLETE_SIGNUP_TEMPLATE_ID: "d-192734136bce4b8daead0201d9b267cc",
  ENAGAGE_INCOMPLETE_BUSSINESS_TEMPLATE_ID: "d-1745093dedf348baa1c1ab144ebab359",
  ENAGAGE_INCOMPLETE_EVENT_TEMPLATE_ID: "d-7fd00bfcf37a48e580072a966f38977c",
  UPCOMING_EXPERT_EVENT_TEMPLATE_ID: "d-f4cdaf47fcb04373bcf282e26ac121bc",
  UPCOMING_OWNER_EVENT_TEMPLATE_ID: "d-98d0a5a7493a4392b4c06846c1d72e48",
  SEVEN_DAYS_BEFORE_COMMISION_EXPIRE_TEMPLATE_ID: "d-c7d3b0e3536c435398b118b0d9221411",
  ADMIN_REVIEW_TEMPLATE_ID: "d-d2ec02750fa64c338e74cd744df7f3dd",
  ADMIN_NO_RFP_MATCH_TEMPLATE_ID: "d-1cc2c550c65f4f78a705c2a8c176e58c",
  ADMIN_RFP_BID_EXPIRED_TEMPLATE_ID: "d-31ee082c08e149908a7e652860deb43f",
  RFP_PAYOUT_TEMPLATE_ID: "d-9ca74840f63247ea86a31b9d2cef3254",
  RFP_REVIEW_REMEMBER_TEMPLATE_ID: "d-c0cdf0c9d7c2412aa8d26d7a2f4239b2",
  RFP_BID_UPDATED_CUSTOMER_TEMPLATE_ID: "d-7938e92e29354b898b1c6a528c088668",
  RFP_BID_UPDATED_PROVIDER_TEMPLATE_ID: "d-08d490980e1e43c6973b0d701f64005c",
};

// NOTE: This file imports sanitize.js, which may lead to circular dependency

/**
 * Combine the given relationships objects
 *
 * See: http://jsonapi.org/format/#document-resource-object-relationships
 */
export const combinedRelationships = (oldRels, newRels) => {
  if (!oldRels && !newRels) {
    // Special case to avoid adding an empty relationships object when
    // none of the resource objects had any relationships.
    return null;
  }
  return { ...oldRels, ...newRels };
};

/**
 * Combine the given resource objects
 *
 * See: http://jsonapi.org/format/#document-resource-objects
 */
export const combinedResourceObjects = (oldRes, newRes) => {
  const { id, type } = oldRes;
  if (newRes.id.uuid !== id.uuid || newRes.type !== type) {
    throw new Error('Cannot merge resource objects with different ids or types');
  }
  const attributes = newRes.attributes || oldRes.attributes;
  const attributesOld = oldRes.attributes || {};
  const attributesNew = newRes.attributes || {};
  // Allow (potentially) sparse attributes to update only relevant fields
  const attrs = attributes ? { attributes: { ...attributesOld, ...attributesNew } } : null;
  const relationships = combinedRelationships(oldRes.relationships, newRes.relationships);
  const rels = relationships ? { relationships } : null;

  return { id, type, ...attrs, ...rels };
};

/**
 * Combine the resource objects form the given api response to the
 * existing entities.
 */
export const updatedEntities = (oldEntities, apiResponse, sanitizeConfig = {}) => {
  const { data, included = [] } = apiResponse;
  const objects = (Array.isArray(data) ? data : [data]).concat(included);

  const newEntities = objects.reduce((entities, curr) => {
    const { id, type } = curr;

    // Some entities (e.g. listing and user) might include extended data,
    // you should check if src/util/sanitize.js needs to be updated.
    const current = sanitizeEntity(curr, sanitizeConfig);

    entities[type] = entities[type] || {};
    const entity = entities[type][id.uuid];
    entities[type][id.uuid] = entity ? combinedResourceObjects({ ...entity }, current) : current;

    return entities;
  }, oldEntities);

  return newEntities;
};

/**
 * Denormalise the entities with the resources from the entities object
 *
 * This function calculates the dernormalised tree structure from the
 * normalised entities object with all the relationships joined in.
 *
 * @param {Object} entities entities object in the SDK Redux store
 * @param {Array<{ id, type }} resources array of objects
 * with id and type
 * @param {Boolean} throwIfNotFound wheather to skip a resource that
 * is not found (false), or to throw an Error (true)
 *
 * @return {Array} the given resource objects denormalised that were
 * found in the entities
 */
export const denormalisedEntities = (entities, resources, throwIfNotFound = true) => {
  const denormalised = resources.map(res => {
    const { id, type } = res;
    const entityFound = entities[type] && id && entities[type][id.uuid];
    if (!entityFound) {
      if (throwIfNotFound) {
        throw new Error(`Entity with type "${type}" and id "${id ? id.uuid : id}" not found`);
      }
      return null;
    }
    const entity = entities[type][id.uuid];
    const { relationships, ...entityData } = entity;

    if (relationships) {
      // Recursively join in all the relationship entities
      return reduce(
        relationships,
        (ent, relRef, relName) => {
          // A relationship reference can be either a single object or
          // an array of objects. We want to keep that form in the final
          // result.
          const hasMultipleRefs = Array.isArray(relRef.data);
          const multipleRefsEmpty = hasMultipleRefs && relRef.data.length === 0;
          if (!relRef.data || multipleRefsEmpty) {
            ent[relName] = hasMultipleRefs ? [] : null;
          } else {
            const refs = hasMultipleRefs ? relRef.data : [relRef.data];

            // If a relationship is not found, an Error should be thrown
            const rels = denormalisedEntities(entities, refs, true);

            ent[relName] = hasMultipleRefs ? rels : rels[0];
          }
          return ent;
        },
        entityData
      );
    }
    return entityData;
  });
  return denormalised.filter(e => !!e);
};

/**
 * Denormalise the data from the given SDK response
 *
 * @param {Object} sdkResponse response object from an SDK call
 *
 * @return {Array} entities in the response with relationships
 * denormalised from the included data
 */
export const denormalisedResponseEntities = sdkResponse => {
  const apiResponse = sdkResponse.data;
  const data = apiResponse.data;
  const resources = Array.isArray(data) ? data : [data];

  if (!data || resources.length === 0) {
    return [];
  }

  const entities = updatedEntities({}, apiResponse);
  return denormalisedEntities(entities, resources);
};

/**
 * Denormalize JSON object.
 * NOTE: Currently, this only handles denormalization of image references
 *
 * @param {JSON} data from Asset API (e.g. page asset)
 * @param {JSON} included array of asset references (currently only images supported)
 * @returns deep copy of data with images denormalized into it.
 */
const denormalizeJsonData = (data, included) => {
  let copy;

  // Handle strings, numbers, booleans, null
  if (data === null || typeof data !== 'object') {
    return data;
  }

  // At this point the data has typeof 'object' (aka Array or Object)
  // Array is the more specific case (of Object)
  if (data instanceof Array) {
    copy = data.map(datum => denormalizeJsonData(datum, included));
    return copy;
  }

  // Generic Objects
  if (data instanceof Object) {
    copy = {};
    Object.entries(data).forEach(([key, value]) => {
      // Handle denormalization of image reference
      const hasImageRefAsValue =
        typeof value == 'object' && value?._ref?.type === 'imageAsset' && value?._ref?.id;
      // If there is no image included,
      // the _ref might contain parameters for image resolver (Asset Delivery API resolves image URLs on the fly)
      const hasUnresolvedImageRef = typeof value == 'object' && value?._ref?.resolver === 'image';

      if (hasImageRefAsValue) {
        const foundRef = included.find(inc => inc.id === value._ref?.id);
        copy[key] = foundRef;
      } else if (hasUnresolvedImageRef) {
        // Don't add faulty image ref
        // Note: At the time of writing, assets can expose resolver configs,
        //       which we don't want to deal with.
      } else {
        copy[key] = denormalizeJsonData(value, included);
      }
    });
    return copy;
  }

  throw new Error("Unable to traverse data! It's not JSON.");
};

/**
 * Denormalize asset json from Asset API.
 * @param {JSON} assetJson in format: { data, included }
 * @returns deep copy of asset data with images denormalized into it.
 */
export const denormalizeAssetData = assetJson => {
  const { data, included } = assetJson || {};
  return denormalizeJsonData(data, included);
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} transaction entity object, which is to be ensured against null values
 */
export const ensureTransaction = (transaction, booking = null, listing = null, provider = null) => {
  const empty = {
    id: null,
    type: 'transaction',
    attributes: {},
    booking,
    listing,
    provider,
  };
  return { ...empty, ...transaction };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} booking entity object, which is to be ensured against null values
 */
export const ensureBooking = booking => {
  const empty = { id: null, type: 'booking', attributes: {} };
  return { ...empty, ...booking };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureListing = listing => {
  const empty = {
    id: null,
    type: 'listing',
    attributes: { publicData: {} },
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureOwnListing = listing => {
  const empty = {
    id: null,
    type: 'ownListing',
    attributes: { publicData: {} },
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} user entity object, which is to be ensured against null values
 */
export const ensureUser = user => {
  const empty = { id: null, type: 'user', attributes: { profile: {} } };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} current user entity object, which is to be ensured against null values
 */
export const ensureCurrentUser = user => {
  const empty = { id: null, type: 'currentUser', attributes: { profile: {} }, profileImage: {} };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} time slot entity object, which is to be ensured against null values
 */
export const ensureTimeSlot = timeSlot => {
  const empty = { id: null, type: 'timeSlot', attributes: {} };
  return { ...empty, ...timeSlot };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureDayAvailabilityPlan = availabilityPlan => {
  const empty = { type: 'availability-plan/day', entries: [] };
  return { ...empty, ...availabilityPlan };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureAvailabilityException = availabilityException => {
  const empty = { id: null, type: 'availabilityException', attributes: {} };
  return { ...empty, ...availabilityException };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensureStripeCustomer = stripeCustomer => {
  const empty = { id: null, type: 'stripeCustomer', attributes: {} };
  return { ...empty, ...stripeCustomer };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensurePaymentMethodCard = stripePaymentMethod => {
  const empty = {
    id: null,
    type: 'stripePaymentMethod',
    attributes: { type: 'stripe-payment-method/card', card: {} },
  };
  const cardPaymentMethod = { ...empty, ...stripePaymentMethod };

  if (cardPaymentMethod.attributes.type !== 'stripe-payment-method/card') {
    throw new Error(`'ensurePaymentMethodCard' got payment method with wrong type.
      'stripe-payment-method/card' was expected, received ${cardPaymentMethod.attributes.type}`);
  }

  return cardPaymentMethod;
};

/**
 * Get the display name of the given user as string. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned or deleted users, a translated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayNameAsString = (user, defaultUserDisplayName) => {
  const hasDisplayName = user?.attributes?.profile?.displayName;

  if (hasDisplayName) {
    return user.attributes.profile.displayName;
  } else {
    return defaultUserDisplayName || '';
  }
};

/**
 * DEPRECATED: Use userDisplayNameAsString function or UserDisplayName component instead
 *
 * @param {propTypes.user} user
 * @param {String} bannedUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayName = (user, bannedUserDisplayName) => {
  console.warn(
    `Function userDisplayName is deprecated!
User function userDisplayNameAsString or component UserDisplayName instead.`
  );

  return userDisplayNameAsString(user, bannedUserDisplayName);
};

/**
 * Get the abbreviated name of the given user. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned  or deleted users, a default abbreviated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserAbbreviatedName
 *
 * @return {String} abbreviated name that can be rendered in the UI
 * (e.g. in Avatar initials)
 */
export const userAbbreviatedName = (user, defaultUserAbbreviatedName) => {
  const hasAttributes = user && user.attributes;
  const hasProfile = hasAttributes && user.attributes.profile;
  const hasDisplayName = hasProfile && user.attributes.profile.abbreviatedName;

  if (hasDisplayName) {
    return user.attributes.profile.abbreviatedName;
  } else {
    return defaultUserAbbreviatedName || '';
  }
};

/**
 * A customizer function to be used with the
 * mergeWith function from lodash.
 *
 * Works like merge in every way exept that on case of
 * an array the old value is completely overridden with
 * the new value.
 *
 * @param {Object} objValue Value of current field, denoted by key
 * @param {Object} srcValue New value
 * @param {String} key Key of the field currently being merged
 * @param {Object} object Target object that is receiving values from source
 * @param {Object} source Source object that is merged into object param
 * @param {Object} stack Tracks merged values
 *
 * @return {Object} New value for objValue if the original is an array,
 * otherwise undefined is returned, which results in mergeWith using the
 * standard merging function
 */
export const overrideArrays = (objValue, srcValue, key, object, source, stack) => {
  if (isArray(objValue)) {
    return srcValue;
  }
};

/**
 * Humanizes a line item code. Strips the "line-item/" namespace
 * definition from the beginnign, replaces dashes with spaces and
 * capitalizes the first character.
 *
 * @param {string} code a line item code
 *
 * @return {string} returns the line item code humanized
 */
export const humanizeLineItemCode = code => {
  if (!/^line-item\/.+/.test(code)) {
    throw new Error(`Invalid line item code: ${code}`);
  }
  const lowercase = code.replace(/^line-item\//, '').replace(/-/g, ' ');

  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

export const submitDetailPanelData = (values) => {
  const { boothType, ...rest } = values;
  const boothTypeChilds = { ...rest };

  for (let i = 0; i < boothType.length; i++) {
    const eachElement = boothType[i];
    for (const key in rest) {

      if (key == "boothTypeOther") {
        boothTypeChilds["boothTypeOther"] = rest[key];
      }

      else if (key == eachElement) {
        boothTypeChilds[key] = rest[key];
      }

      else if (key == (eachElement + "Quantity")) {
        boothTypeChilds[key] = rest[key] || 0;
      }
      else if (key == (eachElement + "Attendant")) {
        boothTypeChilds[key] = rest[key] || 0;
      }
      else if (key == (eachElement + "Other")) {
        boothTypeChilds[key] = rest[key];
      }

      else if (key == (eachElement + "Equipment")) {
        boothTypeChilds[key] = rest[key];
      }

      else if (key == (eachElement + "Other" + "Equipment")) {
        boothTypeChilds[key] = rest[key];
      }

      else if (key == (eachElement + "backdropPreference")) {
        boothTypeChilds[key] = rest[key];
      }

      else if (key == (eachElement + "firstBackdropStyle")) {
        boothTypeChilds[key] = rest[key];
      }

      else if (key == (eachElement + "secondBackdropStyle")) {
        boothTypeChilds[key] = rest[key];
      }
    }

  }

  return { boothType, boothTypeChilds }
}

export const allSubCategoriesMakeFilters = (values) => {

  const {
    ipad = [],
    ipadEquipment = [],
    ipadCamera = [],
    ipadCameraEquipment = [],
    pcCamera = [],
    pcCameraEquipment = [],
    roamingBooths = [],
    roamingBoothsEquipment = [],
    roamingPhotography = [],
    roamingPhotographyEquipment = [],
  } = values;

  return {
    ipad,
    ipadEquipment,
    ipadCamera,
    ipadCameraEquipment,
    pcCamera,
    pcCameraEquipment,
    roamingBooths,
    roamingBoothsEquipment,
    roamingPhotography,
    roamingPhotographyEquipment,
    "360Video": values["360Video"] || [],
    "360VideoEquipment": values["360VideoEquipment"] || [],
  }
}

export const submitBoothDetailPanelData = (values) => {
  const { boothType, ...rest } = values;
  const boothTypeChilds = { ...rest };
  for (let i = 0; i < boothType.length; i++) {
    const eachElement = boothType[i];
    for (const key in rest) {

      if (key == "boothTypeOther") {
        boothTypeChilds["boothTypeOther"] = rest[key];
      }

      else if (key == eachElement) {
        boothTypeChilds[key] = rest[key];
      }

      else if (key == (eachElement + "Quantity")) {
        boothTypeChilds[key] = rest[key] || 0;
      }

      else if (key == (eachElement + "Budget")) {
        boothTypeChilds[key] = rest[key] || 0;
      }

      else if (key == (eachElement + "Other")) {
        boothTypeChilds[key] = rest[key];
      }

      else if (key == (eachElement + "Equipment")) {
        boothTypeChilds[key] = rest[key];
      }

      else if (key == (eachElement + "OtherEquipment")) {
        boothTypeChilds[key] = rest[key];
      }

      else if (key == (eachElement + "Quantity")) {
        boothTypeChilds[key] = rest[key] || 0;
      }

      else if (key == (eachElement + "firstBackdropStyle")) {
        boothTypeChilds[key] = rest[key];
      }
      else if (key == (eachElement + "secondBackdropStyle")) {
        boothTypeChilds[key] = rest[key];
      }


    }

  }

  return { boothType, boothTypeChilds }
}

export const getDetailPanelData = (publicData) => {
  const { boothType, boothTypeChilds } = publicData;
  const getDetailPanelDataValues = {};

  if (boothType) {
    getDetailPanelDataValues["boothType"] = boothType;
  }

  if (boothTypeChilds && typeof boothTypeChilds == "object") {
    for (const key in boothTypeChilds) {
      getDetailPanelDataValues[key] = boothTypeChilds[key];
    }
  }

  return getDetailPanelDataValues;
}


export const getBusinessListingParams = (currentUser) => {
  /**
   * http://localhost:3000/l/:slug/:id/:type/:tab/:listingType >> Prototype
   * http://localhost:3000/l/draft/00000000-0000-0000-0000-000000000000/new/event-details/business >> New
   * http://localhost:3000/l/test-company/65cc9ad7-b0c4-4a0e-9181-8342eebe60a7/draft/details/business >> Draft
   * http://localhost:3000/l/test-company/65cc9125-0c41-4f14-a0ef-a45b919d5220/edit/details/business  >> Published
   */

  const {
    publicData,
    protectedData
  } = (currentUser && currentUser.id && currentUser.attributes.profile) || {};
  const {
    businessListingId = false,
    businessListingPublished = false
  } = protectedData || {};
  const { companyName = "business-company" } = publicData || {};

  const slug = businessListingId ? createSlug(companyName) : 'draft';
  const type = (businessListingPublished && businessListingPublished == BUSINESS_LISTING_TYPE_PUBLISHED)
    ? 'edit'
    : businessListingId
      ? 'draft'
      : 'new';

  return {
    slug, type,
    id: businessListingId || "00000000-0000-0000-0000-000000000000",
    tab: "details",
    listingType: "business"
  };
}


export const sendEventMailData = (listing, currentUser) => {
  const { title = "", geolocation, publicData = {} } = listing.attributes;
  const { eventDate, boothType, venueAddress, eventTitle } = publicData;
  const { firstName, lastName } = currentUser.attributes.profile;
  const { lat = 40.748466, lng = -73.98554 } = geolocation || {};
  const filteredBoothType = boothTypes.filter(bt => boothType.includes(bt.key));

  return {
    "_geoloc": {
      lat,
      lng
    },
    boothType: filteredBoothType,
    customerName: `${firstName} ${lastName}`,
    customerEmail: currentUser.attributes.email,
    rfpBudget: "$100",
    eventDate: eventDate,
    venueName: eventTitle,
    venueAddress: venueAddress?.search || "",
    eventUrl: `${REACT_APP_MARKETPLACE_ROOT_URL}/openInbox/open/${listing.id.uuid}`
  };
}

export const makeBidData = (eventListing, businessListing) => {
  const { title, publicData } = eventListing.attributes;
  const { title: businessTitle, publicData: businessPublicData } = businessListing.attributes;
  return {
    eventListingData: { title, publicData },
    businessListingData: { title: businessTitle, publicData: businessPublicData },
    eventStartDateUnix: publicData.eventStartDateUnix,
    eventEndDateUnix: publicData.eventEndDateUnix
  }
};

export const getCommisionPrices = (commission, currentUser, response) => {
  const { providerCommission, customerCommission } = (Array.isArray(commission?.data) && commission?.data.length) ? commission.data[0].attributes.data.providerCommission : {};
  const providerPercentageCommission = providerCommission?.percentage || 0;
  const customerPercentageCommission = customerCommission?.percentage || 0;

  const { ExpirationDate, ProviderCommission } = currentUser.attributes.profile.privateData;
  const providerCommissionMaybe = ExpirationDate && ProviderCommission && (moment(ExpirationDate, "DD-MM-YYYY").unix() > moment().unix()) ? ProviderCommission : false;
  const customerCommisionMaybe = response.customerCommisionMaybe?.CustomerCommission ? response.customerCommisionMaybe.CustomerCommission : false;

  return { adminCustomerCommisionMaybe: (customerCommisionMaybe || customerPercentageCommission), adminProviderCommissionMaybe: (providerCommissionMaybe || providerPercentageCommission) };
}

// Copy to clipboards
export const copyToClipboard = text => {
  let dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

// Get page url
export const getPageURL = () => {
  if (typeof window !== 'undefined') {
    const pageUrl = (process.env.REACT_APP_MARKETPLACE_ROOT_URL) + window.location.pathname + window.location.search;
    return pageUrl;
  }
}