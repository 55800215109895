import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconAddDocsAndPPT.module.css';

const IconAddDocsAndPPT = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
<svg xmlns="http://www.w3.org/2000/svg" width="66" height="57" viewBox="0 0 66 57" fill="none">
<path d="M54.7332 20.3918C54.6029 20.134 54.4204 19.8983 54.1943 19.6959L37.6943 5.44587C37.4598 5.25061 37.187 5.09298 36.8885 4.98037C36.806 4.94712 36.718 4.92812 36.63 4.902C36.3999 4.83437 36.1604 4.79364 35.9177 4.78088C35.86 4.77613 35.8078 4.75 35.75 4.75H16.5C13.4667 4.75 11 6.88037 11 9.5V47.5C11 50.1196 13.4667 52.25 16.5 52.25H49.5C52.5332 52.25 55 50.1196 55 47.5V21.375C55 21.3251 54.9697 21.28 54.9642 21.2277C54.9508 21.0181 54.9036 20.811 54.824 20.6126C54.7965 20.5366 54.7718 20.463 54.7332 20.3918ZM45.6115 19H38.5V12.8582L45.6115 19ZM16.5 47.5V9.5H33V21.375C33 22.0049 33.2897 22.609 33.8055 23.0544C34.3212 23.4998 35.0207 23.75 35.75 23.75H49.5L49.5055 47.5H16.5Z" fill="black"/>
<path d="M22 28.5H44V33.25H22V28.5ZM22 38H44V42.75H22V38ZM22 19H27.5V23.75H22V19Z" fill="black"/>
</svg>
  );
};

IconAddDocsAndPPT.defaultProps = { className: null };

IconAddDocsAndPPT.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconAddDocsAndPPT;
