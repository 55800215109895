/**
 * Transaction process graph for bookings:
 *   - default-booking
 */

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

// transition/request-payment
// pending-payment 

// transition/expire-payment 
// state/payment-expired

// transition/confirm-payment 
// state/preauthorized

// transition/complete
// state/complete

// transition/operator-complete
// state/delivered

// transition/review-1-by-customer
// state/reviewed-by-customer

// transition/review-1-by-provider
// state/reviewed-by-provider

// transition/review-2-by-customer
// state/reviewed

// transition/review-2-by-provider
// state/reviewed


export const transitions = {
    REQUEST_PAYMENT: 'transition/request-payment',
    EXPIRE_PAYMENT: 'transition/expire-payment',
    CONFIRM_PAYMENT: 'transition/confirm-payment',
    COMPLETE: 'transition/complete',
    OPERATOR_COMPLETE: 'transition/operator-complete',
    // Reviews are given through transaction transitions. Review 1 can be
    REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
    REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
    REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
    REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
};

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
// 
export const states = {
    INITIAL: 'initial',
    PENDING_PAYMENT: 'pending-payment',
    PAYMENT_EXPIRED: 'payment-expired',
    PREAUTHORIZED: 'preauthorized',
    COMPLETE: "complete",
    DELIVERED: 'delivered',
    REVIEWED_BY_CUSTOMER: 'reviewed-by-customer',
    REVIEWED_BY_PROVIDER: 'reviewed-by-provider',
    REVIEWED: 'reviewed',
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const graph = {
    // id is defined only to support Xstate format.
    // However if you have multiple transaction processes defined,
    // it is best to keep them in sync with transaction process aliases.
    id: 'default-accept-bid/release-1',

    // This 'initial' state is a starting point for new transaction
    initial: states.INITIAL,

    // States
    states: {
        [states.INITIAL]: {
            on: {
                [transitions.REQUEST_PAYMENT]: states.PENDING_PAYMENT,
            },
        },

        [states.PENDING_PAYMENT]: {
            on: {
                [transitions.EXPIRE_PAYMENT]: states.PAYMENT_EXPIRED,
                [transitions.CONFIRM_PAYMENT]: states.PREAUTHORIZED,
            },
        },

        [states.PAYMENT_EXPIRED]: {},
        [states.PREAUTHORIZED]: {
            on: {
                [transitions.COMPLETE]: states.COMPLETE,

            },
        },


        [states.COMPLETE]: {
            on: {
                [transitions.OPERATOR_COMPLETE]: states.DELIVERED,
            },
        },

        [states.DELIVERED]: {
            on: {
                [transitions.REVIEW_1_BY_CUSTOMER]: states.REVIEWED_BY_CUSTOMER,
                [transitions.REVIEW_1_BY_PROVIDER]: states.REVIEWED_BY_PROVIDER,
            },
        },

        [states.REVIEWED_BY_CUSTOMER]: {
            on: {
                [transitions.REVIEW_2_BY_PROVIDER]: states.REVIEWED,
            },
        },
        [states.REVIEWED_BY_PROVIDER]: {
            on: {
                [transitions.REVIEW_2_BY_CUSTOMER]: states.REVIEWED,
            },
        },
        [states.REVIEWED]: { type: 'final' },
    },
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant


export const isRelevantPastTransition = transition => {
    return [
        transitions.CONFIRM_PAYMENT,
        transitions.COMPLETE,
        transitions.OPERATOR_COMPLETE,
        transitions.REVIEW_1_BY_CUSTOMER,
        transitions.REVIEW_1_BY_PROVIDER,
        transitions.REVIEW_2_BY_CUSTOMER,
        transitions.REVIEW_2_BY_PROVIDER,
    ].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isCustomerReview = transition => {
    return [transitions.REVIEW_1_BY_CUSTOMER, transitions.REVIEW_2_BY_CUSTOMER].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isProviderReview = transition => {
    return [transitions.REVIEW_1_BY_PROVIDER, transitions.REVIEW_2_BY_PROVIDER].includes(transition);
};

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
    return [transitions.REQUEST_PAYMENT].includes(
        transition
    );
};


// Check when transaction is completed (booking over)
export const isCompleted = transition => {
    const txCompletedTransitions = [
        transitions.CONFIRM_PAYMENT,
        transitions.COMPLETE,
        transitions.OPERATOR_COMPLETE,
        transitions.REVIEW_1_BY_CUSTOMER,
        transitions.REVIEW_1_BY_PROVIDER,
        transitions.REVIEW_2_BY_CUSTOMER,
        transitions.REVIEW_2_BY_PROVIDER,
    ];
    return txCompletedTransitions.includes(transition);
};

// Check when transaction is refunded (booking did not happen)
// In these transitions action/stripe-refund-payment is called
export const isRefunded = transition => {
    const txRefundedTransitions = [
        transitions.EXPIRE_PAYMENT
    ];
    return txRefundedTransitions.includes(transition);
};

export const statesNeedingProviderAttention = [states.PREAUTHORIZED];
