import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../util/reactIntl';
import { SIGNUP_TAB_COMPLETED } from '../../util/types';
import { getBusinessListingParams } from '../../util/data';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';

import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';

const UserNav = props => {
  const {
    className,
    rootClassName,
    params,
    currentPage,
    currentUser,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const {
    publicData,
    protectedData
  } = (currentUser && currentUser.id && currentUser.attributes.profile) || {};
  const { isAdmin } = publicData || {};
  const {
    signUpCompleted,
    businessListingId = false,
    // businessListingPublished = false
  } = protectedData || {};
  const inboxPage = ['DashboardBasePage', 'DashboardPage', 'ProviderDashBoardPage'].includes(currentPage);

  const tabs = [{
    text: <FormattedMessage id="UserNav.profileSettings" />,
    selected: currentPage === 'ProfileSettingsPage',
    disabled: false,
    linkProps: {
      name: 'ProfileSettingsPage',
    },
  },
  {
    text: <FormattedMessage id="UserNav.accountSettings" />,
    selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
    disabled: false,
    linkProps: {
      name: 'ContactDetailsPage',
    },
  }];

  if (signUpCompleted == SIGNUP_TAB_COMPLETED) {
    tabs.push({
      text: <FormattedMessage id="UserNav.editApplication" />,
      selected: currentPage === 'SignUpStepsPage',
      linkProps: {
        name: 'SignUpStepsPage',
        params: { tab: "detail" }
      },
    });
  }

  if (businessListingId) {
    tabs.push({
      text: <FormattedMessage id="UserNav.editBusiness" />,
      selected: currentPage === 'EditListingPage',
      linkProps: {
        name: 'EditListingPage',
        params: getBusinessListingParams(currentUser)
      },
    });
  }

  const dashboardTabs = [{
    text: <FormattedMessage id="UserNav.bidderDashboardPage" />,
    selected: ("owner" == params.tab),
    disabled: false,
    linkProps: {
      name: 'DashboardPage',
      params: {
        tab: 'owner'
      }
    },
  },
  {
    text: <FormattedMessage id="UserNav.ownerDashBoardPage" />,
    selected: "ProviderDashBoardPage" == currentPage,
    disabled: false,
    linkProps: {
      name: 'ProviderDashBoardPage',
    },
  }];

  if (isAdmin) {
    dashboardTabs.push({
      text: <FormattedMessage id="UserNav.adminDashboardPage" />,
      selected: ("admin" == params.tab),
      disabled: false,
      linkProps: {
        name: 'DashboardPage',
        params: {
          tab: 'admin'
        }
      },
    });
  }

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={inboxPage ? dashboardTabs : tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
};

export default UserNav;
