import React from "react";
import { createContext, useEffect, useState } from "react";

// Create a context to manage the script loading state
const CloudinaryScriptContext = createContext();

function CloudinaryUploadWidget({ uwConfig, setData , Icon, text, text2, id, form }) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Check if the script is already loaded
    if (!loaded) {
      const uwScript = document.getElementById("uw");
      if (!uwScript) {
        // If not loaded, create and load the script
        const script = document.createElement("script");
        script.setAttribute("async", "");
        script.setAttribute("id", "uw");
        script.src = "https://upload-widget.cloudinary.com/global/all.js";
        script.addEventListener("load", () => setLoaded(true));
        document.body.appendChild(script);
      } else {
        // If already loaded, update the state
        setLoaded(true);
      }
    }
  }, [loaded]);

  const initializeCloudinaryWidget = () => {
    if (loaded) {
      var myWidget = window.cloudinary.createUploadWidget(
        uwConfig,
        (error, result) => {
          if (!error && result && result.event === "success") {
            setData((prev)=> {
              const prevIousData = Array.isArray(prev) ? prev : [];
              prevIousData.push({ uri: result.info.secure_url, resource_type: result.info.resource_type, id:result.info.asset_id , format:result.info.format
              });
              setTimeout(()=>form.change("uploadImagesTest",result.info.secure_url),500)
              return prevIousData
            })
          }
        }
      );

      myWidget.open();

      // document.getElementById(`${id}upload_widget`).addEventListener(
      //   "click",
      //   function () {
      //     myWidget.open();
      //   },
      //   false
      // );
    }
  };

  return (
    <CloudinaryScriptContext.Provider value={{ loaded }}>
      <div
        type="button"

        id={`${id}upload_widget`}
        // className="cloudinary-button"
        onClick={initializeCloudinaryWidget}
      >
        <span>{text}</span>
        <div>{text2}</div>
        <div>
          <Icon />
        </div>
      </div>
    </CloudinaryScriptContext.Provider>
  );
}

export default CloudinaryUploadWidget;
export { CloudinaryScriptContext };
