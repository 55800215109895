import { onGetCommisonOfUser, onUpdateEventBidListing, updateAlgoliaData } from '../../util/api';
import { getCommisionPrices } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { bidIntiateOrder } from '../CheckoutPage/CheckoutPage.duck';
import { fetchCommission } from '../TransactionPage/TransactionPage.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/OpenInboxPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/OpenInboxPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/OpenInboxPage/FETCH_LISTINGS_ERROR';

export const SUBMIT_BID_REQUEST = 'app/OpenInboxPage/SUBMIT_BID_REQUEST';
export const SUBMIT_BID_SUCCESS = 'app/OpenInboxPage/SUBMIT_BID_SUCCESS';
export const SUBMIT_BID_ERROR = 'app/OpenInboxPage/SUBMIT_BID_ERROR';

// ================ Reducer ================ //
const initialState = {
    txId: null,
    eventId: null,
    fetchListingsError: null,
    fetchListingInProgress: false,
    bidSubmitted: false,
    submitBidError: null,
    submitBidInProgress: false,
};

export default function OpenInboxPageReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_LISTINGS_REQUEST:
            return { ...state, eventId: payload.id, fetchListingInProgress: true, fetchListingsError: null };
        case FETCH_LISTINGS_SUCCESS:
            return { ...state, fetchListingInProgress: false, txId: ((payload && payload.txId) || null), fetchListingsError: null };
        case FETCH_LISTINGS_ERROR:
            return { ...state, fetchListingInProgress: false, fetchListingsError: true };

        case SUBMIT_BID_REQUEST:
            return { ...state, submitBidInProgress: true, submitBidError: null };
        case SUBMIT_BID_SUCCESS:
            return { ...state, submitBidInProgress: false, bidSubmitted: true, submitBidError: null };
        case SUBMIT_BID_ERROR:
            return { ...state, submitBidInProgress: false, submitBidError: true };

        default:
            return state;
    }
}

// ================ Action creators ================ //

const fetchListingRequest = (id) => ({ type: FETCH_LISTINGS_REQUEST, payload: { id } });
const fetchListingsSuccess = (response) => ({ type: FETCH_LISTINGS_SUCCESS, payload: response });
const fetchListingsError = response => ({
    type: FETCH_LISTINGS_ERROR,
    payload: response,
});

const submitBidRequest = () => ({ type: SUBMIT_BID_REQUEST });
const submitBidSuccess = (response) => ({ type: SUBMIT_BID_SUCCESS, });
const submitBidError = response => ({
    type: SUBMIT_BID_ERROR,
    payload: response,
});


// ================ Thunks ================ //

export const submitBid = (values, userId, transaction) => (dispatch, getState) => {
    dispatch(submitBidRequest());
    const requestTransition = "transition/inquire";
    const processAlias = "default-bid/release-1";

    return onGetCommisonOfUser({ userId })
        .then((response) => {
            const { commission } = getState().TransactionPage;
            const { currentUser } = getState().user;
            const {
                adminCustomerCommisionMaybe,
                adminProviderCommissionMaybe
            } = getCommisionPrices(commission, currentUser, response);

            const orderParams = {
                ...values,
                bidData: {
                    ...values.bidData,
                    adminCustomerCommisionMaybe,
                    adminProviderCommissionMaybe
                }
            };

            if (transaction) {
                const { eventListingId, bidData } = values || {};
                const bidTotalAmount = ((bidData.offer.amount) + (bidData.tax && bidData.tax.amount ? bidData.tax.amount : 0));

                updateAlgoliaData({
                    objectID: eventListingId,
                    note: (bidData.note || ""),
                    bidPrice: bidTotalAmount,
                    price: bidData.offer.amount,
                    tax: (bidData.tax.amount || 0),
                    currency: bidData.offer.currency,
                    indexName: process.env.REACT_APP_ALGOLIA_TRANSACTIONS_INDEX,
                });

                dispatch(submitBidSuccess());

                return onUpdateEventBidListing({
                    eventListingId,
                    transactionId: transaction.id.uuid,
                    metadata: {
                        note: (bidData.note || ""),
                        price: bidData.offer.amount,
                        tax: (bidData.tax.amount || 0),
                        currency: bidData.offer.currency,
                    }
                }).then(response => {
                    const { transaction } = response;
                    dispatch(addMarketplaceEntities(transaction));
                    return transaction;
                });
            } else {
                return dispatch(bidIntiateOrder(orderParams, processAlias, requestTransition))
                    .then(res => {
                        dispatch(submitBidSuccess());
                        return res;
                    });
            }
        })
        .catch(e => dispatch(submitBidError(e)));
}

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
    const { tab, id } = params;
    const validTab = tab === 'open' || tab === 'offers';

    if (!validTab) {
        return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
    }

    dispatch(fetchListingRequest(id));

    return Promise.all([sdk.listings.show({
        id: new UUID(id),
        include: ['author'],
    }),
    sdk.transactions.query({ listingId: id, include: ['customer', 'provider'], }),
    dispatch(fetchCommission())])
        .then(response => {
            const listingFields = config?.listing?.listingFields;
            const sanitizeConfig = { listingFields };

            dispatch(addMarketplaceEntities(response[0], sanitizeConfig));
            if (response[1] && response[1].data && response[1].data.data.length) {
                dispatch(addMarketplaceEntities(response[1], sanitizeConfig));
                dispatch(fetchListingsSuccess({
                    txId: response[1].data.data[0].id
                }));
                return response;
            }
            dispatch(fetchListingsSuccess());
            return response;
        })
        .catch(e => dispatch(fetchListingsError(e)));
};
